import { LINKS, PRODUCT_GROUPS, PRODUCT_ORDERING } from '@utils/constants';
import { constructProductSlug } from '@utils/helpers';
import { motion } from 'framer-motion';
import { Link } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';
import React from 'react';

import BorderImage from '@components/BorderImage/BorderImage';
import Box from '@components/Box/Box';
import ButtonGroup from '@components/Layout/ButtonGroup/ButtonGroup';
import Columns from '@components/Layout/Columns/Columns';
import Content from '@components/Layout/Content/Content';
import Grid from '@components/Layout/Grid/Grid';
import PageSection from '@components/Layout/PageSection/PageSection';
import Page from '@components/Master/Page';
import SEO from '@components/Master/SEO';
import Breadcrumbs from '@components/Molecule/Breadcrumbs/Breadcrumbs';
import Button from '@components/Molecule/Button/Button';
import StripCard from '@components/Molecule/StripCard/StripCard';
import EmbedVideo from '@components/Molecule/Video/EmbedVideo';
import Copy from '@components/Typography/Copy';
import H from '@components/Typography/H';
import { MotionH } from '@components/Typography/H';

const ProductCategoryPage = ({ pageContext }: { pageContext: any }) => {
	const { crumbs } = pageContext.breadcrumb;
	const { childImageSharp } = pageContext.fallbackImage;

	const { uid } = pageContext.category
		? pageContext.category
		: pageContext.group;

	const {
		title,
		content,
		key_feature,
		image,
		additional_content,
		cadenas_configurator_url,
		videos,
		seo_title,
		seo_description,
		seo_image,
	} = pageContext.category
		? pageContext.category.data
		: pageContext.group.data;

	const groupsToShow = pageContext.category
		? pageContext.group.edges.filter(({ node }) => {
				return node.data.categorisation.document?.uid === uid;
		  })
		: [];

	groupsToShow.sort(
		(a, b) =>
			PRODUCT_GROUPS.indexOf(a.node.uid) -
			PRODUCT_GROUPS.indexOf(b.node.uid)
	);

	const productsToShow = pageContext.products.edges.filter(({ node }) => {
		return node.data.categorisation.document?.uid === uid;
	});

	productsToShow.sort(
		(a, b) =>
			PRODUCT_ORDERING.indexOf(a.node.uid) -
			PRODUCT_ORDERING.indexOf(b.node.uid)
	);

	return (
		<Page>
			<SEO
				title={`${
					seo_title ? seo_title : title ? title : null
				} | UHV Design`}
				description={seo_description || content?.text}
				image={
					seo_image?.gatsbyImageData?.images.fallback.src ||
					image?.gatsbyImageData?.images.fallback.src
				}
			/>
			<PageSection>
				<Breadcrumbs crumbs={crumbs} />

				<Columns gap="lg">
					<div>
						<Content spacing="md" uniformHeadings>
							<MotionH level={2} as="h1">
								{title}
							</MotionH>

							{cadenas_configurator_url && (
								<ButtonGroup spacing="md">
									<Button
										to="#configurator-tool"
										variant="configure"
										size="sm">
										Configure
									</Button>
								</ButtonGroup>
							)}

							{content?.html && (
								<motion.div
									initial="hidden"
									whileInView="visible"
									viewport={{ once: true, amount: 0.1 }}
									transition={{ duration: 0.35 }}
									variants={{
										visible: { opacity: 1, translateY: 0 },
										hidden: {
											opacity: 0,
											translateY: '1rem',
										},
									}}
									dangerouslySetInnerHTML={{
										__html: content.html,
									}}></motion.div>
							)}
						</Content>

						{key_feature?.html && (
							<Box>
								<Content spacing="sm">
									<H level={3} as="h2">
										Key Features
									</H>

									<div
										dangerouslySetInnerHTML={{
											__html: key_feature.html,
										}}></div>
								</Content>
							</Box>
						)}
					</div>

					<BorderImage
						image={getImage(
							image?.gatsbyImageData
								? image.gatsbyImageData
								: childImageSharp.gatsbyImageData
						)}
						alt={image?.alt ? image.alt : title ? title : ''}
						large
					/>
				</Columns>
			</PageSection>

			{additional_content?.html && (
				<PageSection>
					<Content uniformHeadings constrict>
						<motion.div
							initial="hidden"
							whileInView="visible"
							viewport={{ once: true, amount: 0.1 }}
							transition={{ duration: 0.35 }}
							variants={{
								visible: { opacity: 1, translateY: 0 },
								hidden: {
									opacity: 0,
									translateY: '1rem',
								},
							}}
							dangerouslySetInnerHTML={{
								__html: additional_content.html,
							}}></motion.div>
					</Content>
				</PageSection>
			)}

			{cadenas_configurator_url && (
				<PageSection id="configurator-tool">
					<MotionH level={3} as="h2">
						Product configuration tool
					</MotionH>

					<motion.iframe
						initial="hidden"
						whileInView="visible"
						viewport={{ once: true, amount: 0.1 }}
						transition={{ duration: 0.35 }}
						variants={{
							visible: { opacity: 1 },
							hidden: { opacity: 0 },
						}}
						src={cadenas_configurator_url}
						frameBorder="0"
						width="100%"
						height="700px"></motion.iframe>
				</PageSection>
			)}

			{videos?.length > 0 &&
				videos?.some(({ video_url }) => video_url !== null) && (
					<PageSection>
						{videos.map(
							({
								video_url,
							}: {
								video_url: {
									embed_url: string;
									title: string;
								};
							}) => {
								if (video_url === null) return null;

								return (
									<EmbedVideo
										src={video_url?.embed_url}
										title={
											video_url?.title
												.charAt(0)
												.toUpperCase() +
											video_url?.title
												.slice(1)
												.toLowerCase()
										}
										showTitle
									/>
								);
							}
						)}
					</PageSection>
				)}

			{(productsToShow.length > 0 || groupsToShow.length > 0) && (
				<PageSection background="grey">
					<Content>
						<MotionH level={3}>
							{pageContext.category
								? `${title} series and product options`
								: `${title} product options`}
						</MotionH>
					</Content>

					<Grid>
						{groupsToShow.map(({ node }, key: number) => {
							if (node == null) return null;

							const { title, image } = node.data;

							return (
								<StripCard
									thumbnail={getImage(
										image?.gatsbyImageData
											? image?.gatsbyImageData
											: childImageSharp.gatsbyImageData
									)}
									to={constructProductSlug(
										node,
										pageContext.group
									)}
									title={title}
									key={key}>
									<Content spacing="sm">
										{title && (
											<H level={4} as="h3">
												{title}
											</H>
										)}
									</Content>
								</StripCard>
							);
						})}
						{productsToShow.map(({ node }, key: number) => {
							if (node == null) return null;

							const { title, summary, product_image } = node.data;

							return (
								<StripCard
									thumbnail={getImage(
										product_image?.gatsbyImageData
											? product_image?.gatsbyImageData
											: childImageSharp.gatsbyImageData
									)}
									to={constructProductSlug(
										node,
										pageContext.group
									)}
									title={title}
									key={key}>
									<Content spacing="sm">
										{title && (
											<H level={4} as="h3">
												{title}
											</H>
										)}

										{summary?.html && (
											<div
												dangerouslySetInnerHTML={{
													__html: summary.html,
												}}></div>
										)}
									</Content>
								</StripCard>
							);
						})}
					</Grid>
				</PageSection>
			)}

			<PageSection background="blue">
				<Copy weight="bold" font="xl2" align="center" prose={true}>
					If you would like to discuss your project with our
					engineering team, please{' '}
					<Link to={LINKS.CONTACT} className="underline">
						contact us
					</Link>
					.
				</Copy>
			</PageSection>
		</Page>
	);
};

export default ProductCategoryPage;
